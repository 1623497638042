// src/components/CorsiCaricatiTable.js
import React, { useEffect, useState } from 'react';
import axios from './../axios';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // default theme
import 'react-tabulator/css/tabulator_simple.min.css'; // use Theme(s)


import CourseDetailsModal from './CourseDetailsModal';
import { Sparklines, SparklinesBars } from 'react-sparklines'; // Import Sparklines for the small chart
import ReactDOM from 'react-dom';
import moment from 'moment';
const CorsiCaricatiTable = ({ subtype }) => {
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [graphDataCache, setGraphDataCache] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/anagrafiche/corsi-caricati?subtype=${subtype}`, {
                    withCredentials: true,
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [subtype]);

    const handleOpenModal = (userId) => {
        setSelectedUserId(userId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedUserId(null);
    };


    const handleOpenURL = (pitoken) => {
        const url = `/mtauth?token=${pitoken}`;
        window.open(url, '_self'); // apre il link in una nuova scheda
    };

    const dateFormatter = (cell) => {
        const value = cell.getValue();
        const lastUseMoment = moment(value);
        const twoWeeksAgo = moment().subtract(2, 'weeks');
        var classUtilizzo=(lastUseMoment.isBefore(twoWeeksAgo)) ?"style='color: red;'":"";
        return value ? "<span " + classUtilizzo +">" + moment(value).format('DD/MM/YYYY HH:mm') + "</span>" : "<span style='color: red;font-weight:bold'>*Mai utilizzata*</span>";
    };
    const dateSorter = (a, b, aRow, bRow, column, dir, sorterParams) => {
        // Gestione delle date vuote/null
        if (!a) return -1;
        if (!b) return 1;
    
        const dateA = moment(a, 'YYYY-MM-DD HH:mm:ss');
        const dateB = moment(b, 'YYYY-MM-DD HH:mm:ss');
    
        if (!dateA.isValid()) return -1;
        if (!dateB.isValid()) return 1;
    
        return dir === "asc" ? dateA - dateB :  dateA-dateB;
    };
    const appInstalledFormatter = (cell) => {
        const installDate = cell.getValue();
        const lastUseDate = cell.getRow().getData().datautilizzo;
        const now = moment();
        const lastUseMoment = moment(lastUseDate);
        const installMoment = moment(installDate);
        const twoWeeksAgo = now.subtract(2, 'weeks');

        let icon = '';
        let tooltip = '';

        if (installDate && installMoment.isValid()) {
            if (!lastUseDate || !lastUseMoment.isValid() || lastUseMoment.isBefore(twoWeeksAgo)) {
                icon = '<img src="img/Bullet-ambar.png" alt="Red Bullet" style="width:32px;height:32px;"/>'; // Red bullet
                tooltip = `App non installata o non utilizzata (Ultimo utilizzo ${installMoment.format('DD/MM/YYYY HH:mm')})`;
            } else {
                icon = '<img src="img/Bullet-green.png" alt="Green Bullet" style="width:32px;height:32px;"/>'; // Green bullet
                tooltip = `Installata il ${installMoment.format('DD/MM/YYYY HH:mm')}`;
            }
        } else {
            icon = '<img src="img/Bullet-red.png" alt="Red Bullet" style="width:32px;height:32px;"/>'; // Red bullet
            tooltip = 'App non installata o non attiva';
        }

        return `<span title="${tooltip}">${icon}</span>`;
    };


     // Funzione per formattare la colonna del grafico
     const graphFormatter = (cell, formatterParams, onRendered) => {
        const rowData = cell.getData();
        const userId = rowData.user_id;

        // Se i dati per l'utente sono già nella cache, mostra il grafico
        if (graphDataCache[userId]) {
            return renderGraph(graphDataCache[userId]);
        }

        // Altrimenti, recupera i dati dal server
        fetchGraphData(cell, userId);
        return '<div>Loading...</div>'; // Mostra un messaggio di caricamento temporaneo
    };

    // Funzione per recuperare i dati dell'attività di un utente
    const fetchGraphData = async (cell, userId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('/datahandler/get-attivita', { token, userId });
            const activities = response.data.attivita.map(item => item.numero_attivita);

            // Aggiorna la cache
            setGraphDataCache(prevCache => ({
                ...prevCache,
                [userId]: activities,
            }));

            // Aggiorna il contenuto della cella con il grafico
            const graphHtml = renderGraph(activities);
            cell.getElement().innerHTML = graphHtml;
        } catch (error) {
            console.error('Errore nel recupero dei dati del grafico:', error);
            cell.getElement().innerHTML = '<div>Errore nel caricamento</div>';
        }
    };

    // Funzione per generare l'HTML del grafico con Sparklines
    const renderGraph = (activities) => {
        const container = document.createElement('div');

        const allZero = activities.every(val => val === 0);
        const nonZeroData = activities.filter(val => val > 0);
        const graph = (
            <>
                {allZero ? (
                    // Visualizza un messaggio o lascia la cella vuota se tutti i valori sono zero
                    <div style={{ textAlign: 'center', color: 'gray' }}></div>
                ) : (
                    <div style={{ margin: '0', padding: '0', width: '100%', height: '100%' }}>
                        <Sparklines data={activities} width={220} height={25}   
                         min={1}  
                        max={25} >
                            <SparklinesBars style={{ fill: "green", fillOpacity: ".8" }} />
                        </Sparklines>
                    </div>
                )}
            </>
        );
        ReactDOM.render(graph, container); // Usa ReactDOM per renderizzare React all'interno di un elemento HTML
        return container.innerHTML; // Restituisci l'HTML generato
    };
    


    const tableOptions = {
        height: "900px",
        selectable: true,
        virtualDom: true,
        virtualDomBuffer: 300,
        selectableRowsRangeMode: "click",
        rowFormatter: (row) => {
            const element = row.getElement();
            element.style.maxHeight = "36px";  // Imposta l'altezza massima desiderata
            element.style.overflow = "hidden"; // Nascondi il contenuto in eccesso
        },
     
    };


    const columns = [
        { title: 'Nome', field: 'NomeMT', headerFilter: 'input' },
        { title: 'Cognome', field: 'CognomeMT', headerFilter: 'input' },
        { title: 'Username', field: 'user_login', headerFilter: 'input' },
        { title: 'Email', field: 'user_email', headerFilter: 'input' },
        { title: 'Tipo', field: 'tipoteach', headerFilter: 'input' },
        {
            title: 'Numero Corsi', field: 'num_corsi', headerFilter: 'input', hozAlign: 'center' , formatter: (cell) => {
                const userId = cell.getRow().getData().user_id;
                const button = document.createElement('button');
                button.innerHTML = cell.getValue();
                button.className = 'course-button';
                button.onclick = () => handleOpenModal(userId);
                return button;
            }
        },
        { title: 'N. DinoAlunni', field: 'alunni_totali', headerFilter: 'input', formatter: 'number', hozAlign: 'right' },
        { title: 'Associati', field: 'alunni_associati', headerFilter: 'input', formatter: 'number', hozAlign: 'right' },
        { title: 'Non ass.', field: 'alunni_passati', headerFilter: 'input', formatter: 'number', hozAlign: 'right' },


        { 
            title: 'Visualizza', 
            field: 'pitoken', 
            headerFilter: 'input',  
            hozAlign: 'right',
            formatter: (cell) => {
                const pitoken = cell.getRow().getData().pitoken;
                const url = `/mtauth?token=${pitoken}`;
                
                // Crea l'elemento <a> con l'attributo href
                const link = document.createElement('a');
                link.href = url;
                link.innerHTML = "Visualizza";
                link.className = 'course-button'; // Aggiungi la classe per lo stile del pulsante
        
                // Imposta l'anchor per aprirlo nella stessa scheda
                link.target = "_self"; 
        
                // Restituisci l'elemento <a> come contenuto della cella
                return link;
            }
        
        },
        { title: 'Grafico Attività ultimo mese', field: 'numero_attivita', width:300, formatter: graphFormatter, hozAlign: 'center' },      
        { title: 'App su cellulare', field: 'datainstallazione', headerFilter: 'none', formatter: appInstalledFormatter , sorter: dateSorter},
        { title: 'App Web utilizzata', field: 'datautilizzo', hozAlign: 'center' ,headerFilter: 'none', formatter: dateFormatter ,        sorter: dateSorter},

        
    ];

    return (
        
        <div className="tabulator-container">
             <div>
                <h3>Numero Magic Teachers: {data.length}</h3> {/* Aggiungi la didascalia qui */}
            </div>
            <ReactTabulator
                data={data}
                columns={columns}
                layout="fitData"
                options={tableOptions}
            />
            {selectedUserId && (
                <CourseDetailsModal
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    userId={selectedUserId}
                    subtype={subtype}
                />
            )}
        </div>
    );
};

export default CorsiCaricatiTable;
